html, body
{
    height: 100%;
    padding-right: 0 !important;
    overflow:auto !important;
}

.form {
    /* background-color:#e8e8e8; */
    height:100%;
    padding-top: 5px;
    padding-bottom: 20px;
}

/* .header {
    float: left;
    margin-left: -80px;
    width: 100%;
    background: linear-gradient(to right, #fb8734, #fff);
}

.headerLegend {
    float: left;
    margin-left: -65px;
    width: 100%;
    background: linear-gradient(to right, #fb8734, #fff);
    
} */

.viewHeader {
    float: left;
    text-align: center;
    /* margin-left: -80px; */
    width: -webkit-fill-available;
    /* background: linear-gradient(to right, #fb8734, #fff); */
    background: linear-gradient(to right, #fb8734, #fb8734);
    color: #ffff;
}
/* .main {
    width: 70%;
    padding-left:80px;
    margin-left:80px;
    padding-right:-80px;
    box-shadow: 0px 0px 5px 0px;
    border-radius:5px;
    margin-bottom: 100px;
    padding-bottom:20px;
    background-color: #ffffff;
} */

/* input[type=text],input[type=date], select {
    width:-webkit-fill-available!important;
    border-radius: 0px!important;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
}

input[type=number] {
    width:-webkit-fill-available!important;
    border-radius: 0px!important;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
} */

legend {
    width:fit-content!important;
    padding-left: 40px!important;
}

/* .submit {
    width: 70%;
    float: right;
    margin-bottom: 100px;
} */

/* .header-icon {
    width: 25%;
    float: right;
} */

/* .header-text {
    width:60%;
    float:left
} */

/* .add-row {
    margin-top:8px;
    float: right;
}

.small-info {
    font-size:0.8em;
    color:grey;
} */

.user-info {
    float:right;
}

.view-row {
    width: 50%;
    padding: 6px;
    border: 1px solid #dee2e6;
}
/* .view-row:nth-child(even){background-color: #f2f2f2} */

.view-main-row {
    padding: 6px;
    border: 1px solid #dee2e6;
}
/* .view-main-row:nth-child(even){background-color: #f2f2f2} */

/* .view-main {
    width: 70%;
    padding-left:80px;
    margin-left:80px;
    padding-right:-80px;
    box-shadow: 0px 0px 5px 0px;
    border-radius:5px;
    margin-top: 20px;
    padding-bottom:20px;
    background-color: #ffffff;
} */
.view-main {
    width: 95%;
    /* padding-left:80px; */
    /* margin-left:80px; */
    /* margin: 20px, auto!important, 20px, auto!important; */
    margin-top: 20px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;

    /* padding-right:-80px; */
    /* box-shadow: 0px 0px 5px 5px lightslategrey; */
    /* box-shadow: "6px 6px 6px 6px rgba(60, 61, 65, 0.05)"; */
    box-shadow: 6px 6px 6px 6px #C0C0C0;
    /* box-shadow: 0px 0px 5px 5px #fb8734; */
    
    
    
    /* box-shadow: "6px 8px 8px 20px white"; */
    border-radius:5px;
    /* margin-bottom: 30px; */
    padding-bottom:20px;
    background-color: #ffffff;
}

@media screen and  (max-width: 570px) {
    .view-main {
            width: 98%;
            /* padding-left:8%; */
            /* margin-left:80px; */
            /* margin: 20px, auto!important, 20px, auto!important; */
            margin-top: 5px;
            margin-right: auto;
            margin-bottom: 10px;
            margin-left: auto;
        
            /* padding-right:-80px; */
            /* box-shadow: 0px 0px 5px 5px lightslategrey; */
            /* box-shadow: "6px 6px 6px 6px rgba(60, 61, 65, 0.05)"; */
            box-shadow: 6px 6px 6px 6px #C0C0C0;
            /* box-shadow: 0px 0px 5px 5px #fb8734; */
            
            
            
            /* box-shadow: "6px 8px 8px 20px white"; */
            border-radius:5px;
            /* margin-bottom: 30px; */
            padding-bottom:5px;
            background-color: #ffffff;
    }
    
}


.view-table {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
}

.view-buttons {
    width: 100%;
    /* float: right; */
    /* margin-top: 50px; */
    text-align:center;
}

.view-spinner {
    width: 50%;
    float: right;
    margin-top: 20%;
}
/* button {
    margin:10px!important;
} */

button.close {
    margin:0px!important;
}

.action-buttons {
    margin-right: 5px!important;
    margin-top:0px!important;
    margin-left:0px!important;
    margin-bottom:0px!important;

}
.edit-pictures{
    color: red;
    width: 100%;
    margin-left: -80px;
    margin-top:20px;
}

.ReactTable .rt-thead .rt-tr {
    text-align: left!important;
    font-weight: 700;
}

.ReactTable {
 width:100%!important;
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
    border: 1px solid #6c757d!important;
    border-left:0px!important;
    border-top:0px!important;
    
}

.thumbnails-div {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 8px;
}

.img-wrap {
    position: relative;
    display: inline-block;
    }

.img-wrap .close {
    z-index: 100;
    position: relative;
    background-color: #ec8e4a;
    font-weight: bold;
    cursor: pointer;
   
    text-align: center;
    padding: 4px 3px;
    font-size: 22px;
    line-height: 10px;
    border-radius: 50%;
    border:1px solid red;
    color: black;
    display: inline-block;
}

.img {
    width:50%;
    height:50%;
}
/* @media screen and  (max-width: 570px) {
    .img {
        width:80%;
        height:90%;
    }
    
} */