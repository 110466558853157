:root {
    --header-text-color: #f0f0f0;
    --header-col-color:#545b64;
    --info-color: #17a2b8;
    --warning-color: #fb8734;
    --danger-color: #dc3545;
    --success-color: #0ca730;
    --draft-color: #2362e9;
    --cancelColor : #f56b53;

    --bg-menu-button-color:#424242;
    --bg-success: #d4edda;
    --bg-gray: #6c757d;
    --bg-gf: #fb8734;
    --bg-light-gray:#c1c1c1;
    --bg-shadow: #E0E0E0;

  }