.backBtnDiv{
    text-align: left;
    margin-bottom:-10px;
    margin-left: 2.5%;
    margin-right: 2%;
    display:flex;
    justify-content: flex-start;
}
@media screen and  (max-width: 780px) {
    .backBtnDiv {
        margin-bottom:0px;
        margin-left: 0%;
    }    
}

.backBtn {
    width:100%;
    border-color:#fff;
    box-shadow:0px 1px 2px 2px #C0C0C0;
    background-color: #fb8734;
    min-width: 150px;
}