.main {
    width: 95%;
    /* padding-left:80px; */
    /* margin-left:80px; */
    /* margin: 20px, auto!important, 20px, auto!important; */
    margin-top: 30px;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;

    /* padding-right:-80px; */
    /* box-shadow: 0px 0px 5px 5px lightslategrey; */
    /* box-shadow: "6px 6px 6px 6px rgba(60, 61, 65, 0.05)"; */
    box-shadow: 6px 6px 6px 6px #C0C0C0;
    /* box-shadow: 0px 0px 5px 5px #fb8734; */
    
    
    
    /* box-shadow: "6px 8px 8px 20px white"; */
    border-radius:5px;
    /* margin-bottom: 30px; */
    padding-bottom:20px;
    background-color: #ffffff;
}

@media screen and  (max-width: 570px) {
    .main {
            width: 98%;
            /* padding-left:8%; */
            /* margin-left:80px; */
            /* margin: 20px, auto!important, 20px, auto!important; */
            margin-top: 5px;
            margin-right: auto;
            margin-bottom: 10px;
            margin-left: auto;
        
            /* padding-right:-80px; */
            /* box-shadow: 0px 0px 5px 5px lightslategrey; */
            /* box-shadow: "6px 6px 6px 6px rgba(60, 61, 65, 0.05)"; */
            box-shadow: 6px 6px 6px 6px #C0C0C0;
            /* box-shadow: 0px 0px 5px 5px #fb8734; */
            
            
            
            /* box-shadow: "6px 8px 8px 20px white"; */
            border-radius:5px;
            /* margin-bottom: 30px; */
            padding-bottom:5px;
            background-color: #ffffff;
    }
    
}

.header {
    float: left;
    text-align: center;
    /* margin-left: -80px; */
    width: -webkit-fill-available;
    /* background: linear-gradient(to right, #fb8734, #fff); */
    background: linear-gradient(to right, #fb8734, #fb8734);
    color: #ffff;
}

.headerLegend {
    float: left;
    text-align: center;
    /* margin-left: -65px; */
    /* margin-left:-8%; */
    margin-right: 10%;
    margin-top: 3%;
    margin-bottom: 3%;
    width: -webkit-fill-available;
    background: linear-gradient(to right, #ffff, #fb8734, #fb8734, #ffff);
    /*font-size: x-large;*/
    /* box-shadow: 6px 6px 6px 6px #C0C0C0; */
    color: #ffff;
}

/* button {
    margin:10px!important;
} */
.add-row {
    margin-top:8px;
    float: right;
}

.small-info {
    font-size:0.8em;
    color:grey;
}
.submit {
    width: 100%;
    float: right;
    margin-bottom: 5px;
    align-self: center;
}

input[type=text],input[type=date], select {
    /* width:300px!important; */
    width:-webkit-fill-available;
    border-radius: 5px!important;
    /* border-top: none!important;
    border-left: none!important;
    border-right: none!important; */
}
/* input[type=date] {
    width:-webkit-fill-available;
    border-radius: 5px!important;
    height:calc(1.5em + .75rem + 2px);
    border:1px solid #ced4da;
    padding:.375rem .75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */

input[type=number] {
    width:-webkit-fill-available;
    border-radius: 5px!important;
    /* border-top: none!important;
    border-left: none!important;
    border-right: none!important; */
}

.topBtnDivDecForm {
    text-align: left;
    margin-left: 2%; 
    margin-right: 2.5%; 
    margin-bottom: -2%;
    display:flex;
    justify-content: flex-end;
}
