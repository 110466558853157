.cardCustBody {
    box-shadow: 6px 6px 6px 6px #E0E0E0
}
.actionTag {
    display: inline-block;
}
@media screen and  (max-width: 450px) {
    .actionTag {
        display: none;
    }    
}

