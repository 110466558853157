.logo {
    width: 70%;
    height: 80%;
    margin: 10px;     
}

@media screen and (max-width: 700px) {
    .logo {
        width: 80%;
        height: 80%;
        margin: 5px;
    }
}
.loginCard{
    margin:5% auto 5% auto; 
    width:40%;
    border-radius:30px;
    background-color: rgb(105, 105, 105,0.6);
    /* background-image: url("../../../../assets/gf-name-log.PNG");
    background-size: 100% 100%;     */
    color: white;
}
@media screen and (max-width: 700px) {
    .loginCard {
        margin:2% auto; 
        width:90%;
        border-radius:30px;
        background-color: rgb(105, 105, 105,0.6);
        /* background-image: url("../../../../assets/gf-name-log.PNG"); */
        /* background-size: 100% 100%; */
        color: white;
    }
}
.loginBtn {
    width:250px;
    border-color:#fff;
    box-shadow:0px 1px 2px 2px #C0C0C0;
    
    
}
@media screen and (max-width: 700px) {
    .loginBtn {
        width:100%;
        border-color:#fff;
        box-shadow:0px 1px 2px 2px #C0C0C0;
        
        
    }
}
.loginBtnForm{
    background-image: url("../../../../assets/gf-name-log.jpg");
    /* background-image: url("../../../../assets/gf-login-bg7.jpg"); */
    background-size: 100% 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top:-25px;
    text-align: center;
}
@media screen and (min-width: 450px) and (max-width: 700px) {
    .loginBtnForm{
        background-image: url("../../../../assets/gf-name-log.jpg");
        /* background-image: url("../../../../assets/gf-login-bg7.jpg"); */
        background-size: 100% 100%;
        padding-bottom: 10%;
        padding-top: 7%;
        margin-top:-25px;
        text-align: center;
    }
}
@media screen and (max-width: 450px) {
    .loginBtnForm{
        background-image: url("../../../../assets/gf-name-log.jpg");
        /* background-image: url("../../../../assets/gf-login-bg7.jpg"); */
        background-size: 100% 100%;
        padding-bottom: 35%;
        padding-top: 25%;
        margin-top:-25px;
        text-align: center;
    }
}